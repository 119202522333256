import {React, useState,useEffect}from 'react';
import {NavLink} from "react-router-dom";
import CountUp from "react-countup";
import emailjs from "@emailjs/browser";
import '../App.css';
import './HomeView.css';


{/**initialize variables  */}
let grundIdx;
let grundIdx_links;
let grundIdx_rechts;
let settingsArr = [0,1,2,3,4]


function HomeView(){

    {/*initialize variables for 5 gruende */}
    const [grundH,setGrundH] = useState(0);
    const [grundP,setGrundP] = useState(0);
    const [grundH_links,setGrundH_links] = useState(4)
    const [grundP_links,setGrundP_links] = useState(4)
    const [grundH_rechts,setGrundH_rechts] = useState(1)
    const [grundP_rechts,setGrundP_rechts] = useState(1)
    const gruendeH = ["Effiziente Ressourcennutzung","Reduzierung externer Abhängigkeiten","Kosteneinsparungen und schnelle Amortisation","Umweltfreundliche Unternehmensführung","Industrielle Autarkie und Innovationsführerschaft"]
    const gruendeP =["Mathematische Optimierung maximiert die Nutzung erneuerbarer Energien und vermeidet Ressourcenverschwendung.", "Synergien zwischen Energiesystemen minimieren die Abhängigkeit von externen Energiequellen, für mehr Unabhängigkeit und Stabilität.", "Unsere Strategie senkt Energiekosten, verkürzt die Amortisationsdauer und ermöglicht eine effiziente, kosteneffektive Energieversorgung.", "Die Integration erneuerbarer Technologien reduziert nicht nur CO2-Emissionen, sondern positioniert Ihr Unternehmen als Umweltvorreiter.", "Durch die geschickte Integration erneuerbarer Technologien erreichen Sie einen Grad der Autarkie und positionieren sich als Vorreiter in nachhaltiger Unternehmensführung und Innovation."]

    const settings = ['left:25vw;scale:1;opacity:95%;z-index:9;pointer-events:none','left:50vw;scale:0.8;opacity:70%;z-index:8;cursor:pointer','left:40vw;scale:0.58;z-index:7;pointer-events:none;cursor:none','left:10vw;scale:0.58;z-index:7;pointer-events:none','left:0vw;scale:0.8;opacity:70%;z-index:8']

    {/**initialize count variable */}
    const [startCounting,setStartCounting] =useState(false);

    {/**initialize contact form variables */}
    const [form,setForm] = useState({vorname:"", nachname:"", email:"", unternehmen:"", nachricht:"",datenschutz:false})
    const [msg,setMsg]  = useState("")
    const successMsg = "Ihre Anfrage wurde erfolgreich abgeschickt";
    

    {/**initialize variables for vierschritte */}
    const[style,setStyle] = useState({display:'none',height:0,'maxHeight':0})
    const[style1,setStyle1] = useState({display:'none',height:0,'maxHeight':0})
    const[style2,setStyle2] = useState({display:'none',height:0,'maxHeight':0})
    const[style3,setStyle3] = useState({display:'none',height:0,'maxHeight':0})
        

    {/**set contact form information  */}
    const change = (event)=>{
        setForm({...form, [event.target.name]: event.target.value});
    }
    {/**submit contact form information */}
    const handleSubmit = (event)=>{
        event.preventDefault();

        const values = Object.values(form)
        {/**check if all forms are filled in  */}
        const fill = (values.findIndex(c => c === "" || c === false) === -1)
        console.log(fill)

        {/**if all are filled in send request, else print message */}
        if(fill){
            emailjs.sendForm('service_snm70vm','template_7rv6307', document.getElementById("form"), 'BKtJA9q4G-WPR88IG')
            .then((result)=>{
                setMsg(successMsg);
                // checkbox needs to stay activated to prevent falty behaviour
                setForm({vorname:"", nachname:"", email:"", unternehmen:"", nachricht:"",datenschutz:true});
            }, (error) =>{
                console.log(error)
                setMsg(error)
            });
            setMsg("")
        }
        else{
            setMsg("Bitte alle Felder ausfüllen.")
        }
        
    }

    {/**set animation fuenf gruende */}
    const links =()=>{
        document.getElementById('fuenfgruende_box').setAttribute('style','animation:move-on-left-click 1.5s linear')
        document.getElementById('fuenfgruende_box_links').setAttribute('style','animation: move-on-left-click_foreground_left 1.5s linear')
        document.getElementById('fuenfgruende_box_rechts').setAttribute('style','animation: move-on-left-click_background_right 1.5s linear')
        setTimeout(()=>{
            grundIdx -=1;
        
        if(grundIdx ==-1){
            grundIdx=4;
        }
        setGrundH(grundIdx)
        setGrundP(grundIdx)

        grundIdx_links -=1;
        
        if(grundIdx_links ==-1){
            grundIdx_links=4;
        }
        setGrundH_links(grundIdx_links)
        setGrundP_links(grundIdx_links)

        grundIdx_rechts -=1;
        
        if(grundIdx_rechts ==-1){
            grundIdx_rechts=4;
        }
        setGrundH_rechts(grundIdx_rechts)
        setGrundP_rechts(grundIdx_rechts)
        document.getElementById('fuenfgruende_box').setAttribute('style','animation:none')
        document.getElementById('fuenfgruende_box_links').setAttribute('style','animation:none')
        document.getElementById('fuenfgruende_box_rechts').setAttribute('style','animation:none')

        },1000) 
    }

    {/**set animation for fuenfgruende  */}
    const rechts =()=>{
        document.getElementById('fuenfgruende_box').setAttribute('style','animation:move-on-right-click 1.5s linear')
        document.getElementById('fuenfgruende_box_links').setAttribute('style','animation:move-on-right-click_background_left 1.5s linear')
        document.getElementById('fuenfgruende_box_rechts').setAttribute('style','animation:move-on-right-click_foreground_right 1.5s linear')
        setTimeout(()=>{
            grundIdx +=1;
            if(grundIdx == 5){
                grundIdx = 0;
            }
            setGrundH(grundIdx)
            setGrundP(grundIdx)

            grundIdx_links +=1;
            if(grundIdx_links == 5){
                grundIdx_links = 0;
            }
            setGrundH_links(grundIdx_links)
            setGrundP_links(grundIdx_links)

            grundIdx_rechts +=1;
            if(grundIdx_rechts == 5){
                grundIdx_rechts = 0;
            }
            setGrundH_rechts(grundIdx_rechts)
            setGrundP_rechts(grundIdx_rechts)

            document.getElementById('fuenfgruende_box').setAttribute('style','animation:none')
            document.getElementById('fuenfgruende_box_links').setAttribute('style','animation:none')
            document.getElementById('fuenfgruende_box_rechts').setAttribute('style','animation:none')

        },1000)
    }

    

    function arrayRotate(arr, reverse) {
        if (reverse) arr.unshift(arr.pop());
        else arr.push(arr.shift());
        return arr;
      }

    {/**set animation fuenf gruende */}
    const right =()=>{
        console.log(settingsArr)
        settingsArr = arrayRotate(settingsArr);
        document.getElementById('fuenfgruende_box_1').setAttribute('style', settings[settingsArr[0]])
        document.getElementById('fuenfgruende_box_2').setAttribute('style', settings[settingsArr[1]])
        document.getElementById('fuenfgruende_box_3').setAttribute('style', settings[settingsArr[2]])
        document.getElementById('fuenfgruende_box_4').setAttribute('style', settings[settingsArr[3]])
        document.getElementById('fuenfgruende_box_5').setAttribute('style', settings[settingsArr[4]])
        console.log(settingsArr)
        
      
    }

    const left =()=>{
        settingsArr = arrayRotate(settingsArr,true)
        document.getElementById('fuenfgruende_box_1').setAttribute('style', settings[settingsArr[0]])
        document.getElementById('fuenfgruende_box_2').setAttribute('style', settings[settingsArr[1]])
        document.getElementById('fuenfgruende_box_3').setAttribute('style', settings[settingsArr[2]])
        document.getElementById('fuenfgruende_box_4').setAttribute('style', settings[settingsArr[3]])
        document.getElementById('fuenfgruende_box_5').setAttribute('style', settings[settingsArr[4]])
        

    }

    {/**set animation for fuenfgruende  */}
    const rechtsI =()=>{
        document.getElementById('fuenfgruende_boxI').setAttribute('style','animation:move-on-right-clickI 1.5s linear')
        document.getElementById('fuenfgruende_box_linksI').setAttribute('style','animation:move-on-right-click_background_leftI 1.5s linear')
        document.getElementById('fuenfgruende_box_rechtsI').setAttribute('style','animation:move-on-right-click_foreground_rightI 1.5s linear')
        setTimeout(()=>{
            grundIdx +=1;
            if(grundIdx == 5){
                grundIdx = 0;
            }
            setGrundH(grundIdx)
            setGrundP(grundIdx)

            grundIdx_links +=1;
            if(grundIdx_links == 5){
                grundIdx_links = 0;
            }
            setGrundH_links(grundIdx_links)
            setGrundP_links(grundIdx_links)

            grundIdx_rechts +=1;
            if(grundIdx_rechts == 5){
                grundIdx_rechts = 0;
            }
            setGrundH_rechts(grundIdx_rechts)
            setGrundP_rechts(grundIdx_rechts)

            document.getElementById('fuenfgruende_boxI').setAttribute('style','animation:none')
            document.getElementById('fuenfgruende_box_linksI').setAttribute('style','animation:none')
            document.getElementById('fuenfgruende_box_rechtsI').setAttribute('style','animation:none')

        },1000)
    }


    {/**make counter for zahlen */}
    const makeCounter = (start,end,duration,decimals,decimal)=>{
        
        return( <CountUp start={start} end = {end} duration = {duration} decimals = {decimals} decimal ={decimal} redraw = {false}/>)
             
    }
    {/**control counter for zahlen */}
    const controlCounter = ()=>{
       
        if(document.getElementById('zahlen').getBoundingClientRect().y <= 400){
            if(!startCounting){
                setStartCounting(true)
            }
            
        }
    }

    {/** on anfrage scroll to kontaktformular*/}
    const anfrage = ()=>{
       
        window.location.href= "#kontaktformular";
    }


   
    
  

    useEffect(()=>{
        
        {/**initialize parameters for fuenfgruende */}
        grundIdx = 0;
        grundIdx_links=4;
        grundIdx_rechts=1;

        {/**on scroll start counter */}
        window.addEventListener('scroll', controlCounter)
        return ()=>{
            window.removeEventListener('scroll',controlCounter)
        }
    },[])


    return(
        <>
        
        {/**Energieversorgung */}
        <div className = "wrapper_dark" id = "energieversorgung">
            <h1>ihre ideale Energieversorgung</h1>
            <div className = "content">
                <div className="text">
                    <p>Energiesystemoptimierung ist <br/> der Schlüssel zum sicheren und nachhaltigen Unternehmenserfolg. Unsere maßgeschneiderte Lösung mit erneuerbaren Energien steigert die Effizienz, senkt Kosten und positioniert ihr Unternehmen als Umweltvorreiter. Starten Sie heute in eine sichere, effizientere Zukunft!</p>
                   
                </div>
            
                <div className="image">
                    <img src={require("../assets/sektorengekuppelt.png")}></img>
                </div>
                
            </div>
            <button className="button" onClick={anfrage} >Jetzt anfragen</button>
        </div>

       {/**Mathematisch optimiert */}
        <div className = "wrapper_light" id = "mathematisch">
            <h1>mathematisch optimiert</h1> 
            <div className = "content">
            
                <div className= "backgroundlogo2">
                    <img src={require("../assets/kreis-logo.png")} alt = {"Logo"}></img>
                    
                </div>
                <div  id="grauerKreis">
                    <img src={require("../assets/grauer_kreis.png")} alt = {"Logo"}></img>
                    
                </div>

                <div className = "image">
                    <img src={require("../assets/bildschirm.png")} alt={"display optimierung"}></img>
                </div>

                <div className="text">
                    <p>Unsere mathematische ​Energiesystemoptimierung entwirft ein ​individuell angepasstes Energiesystem, maßgeschneidert für Ihre Betriebsanforderungen.<br/> Mithilfe unserer ​Software berechnen wir präzise die ​optimale Implementierung erneuerbarer ​Technologien nach Ihren Zielen. Erzielen ​Sie langfristig optimale Ergebnisse.</p>
                    <button className="button"><NavLink to= {"/Kontakt"}>Jetzt anfragen</NavLink></button>
                </div>
                
            </div>
            
        </div>

     
    
        

        {/**fuenf gruende für eine energiesystemoptimierung I */}
        <div className = "wrapper_dark" id = "fuenfgruende" >
            <h1>5 gute Gründe für eine Energiesystemoptimierung</h1>
           
            <div className = "list">
                
                <div id="fuenfgruende_box_1" className = "fuenfgruende_box"  >
                    <h2>{gruendeH[0]}</h2>
                    <p>{gruendeP[0]}</p>
                </div>
                <div id="fuenfgruende_box_2" className = "fuenfgruende_box"   >
                    <h2>{gruendeH[1]}</h2>
                    <p>{gruendeP[1]}</p>
                </div>
                <div id="fuenfgruende_box_3"  className = "fuenfgruende_box"   >
                    <h2>{gruendeH[2]}</h2>
                    <p>{gruendeP[2]}</p>
                </div>

                <div id="fuenfgruende_box_4" className = "fuenfgruende_box"  >
                    <h2>{gruendeH[3]}</h2>
                    <p>{gruendeP[3]}</p>
                </div>
                <div id="fuenfgruende_box_5" className = "fuenfgruende_box" >
                    <h2>{gruendeH[4]}</h2>
                    <p>{gruendeP[4]}</p>
                </div>
                <div id="fuenfgruende_click_right" onClick={left}>

                </div>
                <div id="fuenfgruende_click_left" onClick={right}>

                </div>
                
            </div>
        </div>

        {/**vier schritte zu nachhaltiger Energieeffizienz  */}
        <div className = "wrapper_light" id = "vierschritte">
            <h1>
                In vier Schritten zu nachhaltiger Energieeffizienz
            </h1>
            <div className = "content">
                <div className = "text" onMouseEnter = {e =>{window.innerWidth >= 660 ? setStyle({display:'block',width:'auto',height:'auto'}) :setStyle({display:'none',height:0, maxHeight:0})}} onMouseLeave={e=>{window.innerWidth >= 660 ? setStyle({display:'none',height:0, maxHeight:0}):setStyle({display:'none',height:0, maxHeight:0})}}>
                    <div className ="image">
                        <img src={require("../assets/Icon_Ziele.png")} alt = "Icon Ziele"></img>
                    </div>
                    
                    <h3>
                    1. Ziele definieren
                    </h3>
                    <p style={style}>
                    Gemeinsam setzen wir Ihre Ziele für die Optimierung Ihres Energiesystems fest. Ob maximale Rendite, erhöhte Autarkie oder minimierte Amortisation und Emissionen – unsere dynamischen Zielfunktionen ermöglichen maßgeschneiderte Lösungen, die genau Ihren Wünschen entsprechen.
                    </p>

                </div>
                <div className = "text" onMouseEnter = {e =>{window.innerWidth >= 660 ?setStyle1({display:'block',width:'auto',height:'auto'}):setStyle1({display:'none',height:0, maxHeight:0})}} onMouseLeave={e=>{window.innerWidth >= 660 ?setStyle1({display:'none',height:0, maxHeight:0}):setStyle1({display:'none',height:0, maxHeight:0})}}>
                    <div className ="image">
                        <img src={require("../assets/Icon_Datenanalyse.png")} alt = "Icon Datenanalyse"></img>
                    </div>
                    <h3>
                    2. Datenanalyse
                    </h3>
                    <p style={style1}>
                    Wir erfassen alle relevanten Energiedaten Ihres Unternehmens und Standortes. Mit unseren Algorithmen verarbeiten wir umfangreiche Daten von über 15.000 Messpunkten, um Ihnen schnelle Szenarienanalysen zu liefern und Sie vor Marktvolatilität zu schützen.
                    </p>

                </div>
                <div className = "text" onMouseEnter = {e =>{window.innerWidth >= 660 ?setStyle2({display:'block',width:'auto',height:'auto'}):setStyle2({display:'none',height:0, maxHeight:0})}} onMouseLeave={e=>{window.innerWidth >= 660 ?setStyle2({display:'none',height:0, maxHeight:0}):setStyle2({display:'none',height:0, maxHeight:0})}}>
                    <div className ="image">
                        <img src={require("../assets/Icon_Optimierung.png")} alt = "Icon Optimierung"></img>
                    </div>
                    <h3>
                    3. Ganzheitliche Optimierung
                    </h3>
                    <p style={style2}>
                    Unsere Software überarbeitet Ihr Energiesystem ganzheitlich. Durch den Vergleich verschiedener technologischer Kombinationen und Datenpunkte erhalten Sie einen Designvorschlag, der auf fundierten Berechnungen basiert und jeden einzelnen Aspekt Ihrer Energieinfrastruktur berücksichtigt
                    </p>

                </div>
                <div className = "text"  onMouseEnter = {e =>{window.innerWidth >= 660 ?setStyle3({display:'block',width:'auto',height:'auto'}):setStyle3({display:'none',height:0, maxHeight:0})}} onMouseLeave={e=>{window.innerWidth >= 660 ?setStyle3({display:'none',height:0, maxHeight:0}):setStyle3({display:'none',height:0, maxHeight:0})}} >
                    <div className ="image">
                        <img src={require("../assets/Icon_Umsetzung.png")} alt = "Icon Umsetzung"></img>
                    </div>
                    <h3>
                    4. Umsetzung
                    </h3>
                    <p style={style3}>
                    Unser Designvorschlag bietet eine maßgeschneiderte Lösung für Ihr Gewerbe, indem er die optimale Kombination von Technologien wie Photovoltaik, Speichern, Wärmepumpen und mehr vorschlägt. So maximieren Sie Ihre Kostenersparnis und machen Ihr Unternehmen krisensicher.
                    </p>

                </div>

            </div>
            <button className="button" onClick={anfrage} >Jetzt anfragen</button>
        </div>

        
        {/**Zahlen  */}
        <div className = "wrapper_dark" id ="zahlen">
            <h1>Das zahlt sich aus</h1>
            <div className = "list">
                <div>
                    <h2 id = "Kostenersparnis">{startCounting ?  makeCounter(20,69.5,5,1,".") :<span>0</span>}%
                    </h2>
                    <p>Kostenersparnis</p>
                </div>
                <div>
                    <h2>{ startCounting ? makeCounter(20,100,5,0,""):<span>0</span>}%
                    </h2>
                    <p>Autarkiegrad</p>
                </div>
                <div>
                    <h2>{startCounting ? makeCounter(0,6,10,0,""): <span>0</span> }-fache</h2>
                    <p>CO2-Reduktion</p>
                </div>
                <div>
                    <h2>{startCounting ? makeCounter(0,0,10,0,""): <span>3</span>}</h2>
                    <p>Blackouts</p>
                </div>
                <div>
                    <h2>&lt;{startCounting ? makeCounter(0,30,10,0,""): <span>0</span>}%</h2>
                    <p>kürzere Amortisationszeit</p>
                </div>
            </div>
        </div>


        {/**Partner */}
        <div className = "wrapper_light" id="partner">
            <h1>Prüfung - Förderung - Partner</h1>
            
            <div className = "bar">
                {/*
                <div className ="image">
                <img src={require("../assets/logo_bwe.jpg")} alt = "Bundesministerium Wirtschaft und Energie"></img>
                </div>
                <div className="image">
                <img src={require("../assets/logo_digitaleinnovation.png")} alt = "Gründungswettbewerb digitale Innovationen"></img>
                </div>
                
                */}
               
                
                <div className ="image">
                <img src={require("../assets/gruendungsstipendium-logo.jpg")} alt = "gruenderstipendium logo"></img>
                </div>
                <div className="image">
                <img src={require("../assets/gateway_logo.png")} alt = "gateway logo"></img>
                </div>
                <div className ="image">
                <img src= "https://assets-global.website-files.com/631783bc270bba582e8fc6bf/639c392fbf582ddd9f09a885_ignition-logo.svg" ></img>
                </div>
                <div className ="image">
                <img src= "https://assets-global.website-files.com/5dcd2fb55095d01cdae7542e/5de52e9fbd224df2455c2832_digihub_Logo.svg" ></img>
                </div>
                <div className ="image"  id="logo">
                <img src={require("../assets/comsys-sw.png")} alt="comsys communications systems services "></img>
                </div>
                <div className ="image">
                <img src= {require("../assets/kühn_geo_logo.png")} alt="kühn geo consulting" ></img>
                </div>
                
            </div>
        </div>

        {/**Team */}
        <div className = "wrapper_light" id = "team">
            <h1>Die Gründer:innen</h1>
            <div className ="content">
                <div className = "text">
                    <p>Mit unserer tiefgreifenden Expertise im ​Bereich der Energiesystemoptimierung ​bieten wir maßgeschneiderte Lösungen, ​die genau auf die Anforderungen von ​Unternehmen ​zugeschnitten sind. Verlassen Sie sich auf ​uns, um Ihre Energieeffizienz  zu ​maximieren.</p>
                    
                </div>
                <div className="image">
                    <img src={require("../assets/Team_image.png")} alt = "team picture"></img>
                  
                </div>
            </div>
        </div>

        {/**Kontaktformular */}
        <div className = "wrapper_light" id = "kontaktformular">
                
                <h1>Schreiben Sie uns</h1>
                <div className = "content">
                    <p className = "text">Bitte füllen Sie das Kontaktformular aus, wir werden uns dann in Kürze bei Ihnen melden. Gerne können Sie uns auch per <br/> Mail oder direkt telefonisch <br/> kontaktieren. <br/> <br/> M  info@concept-e.solutions <br/> <br/>concept <strong>e</strong><br/>Weyertal 109<br/>50931 Köln</p>
                    <form id = "form" onSubmit = {handleSubmit}>
                        <input type = "text" id = "vorname" name= "vorname" placeholder = "Vorname" onChange = {change} value={form.vorname}></input><nobr/>
                        <input type = "text" id = "nachname" name= "nachname" placeholder = "Nachname" onChange = {change} value={form.nachname}></input><br/>
                        <input type = "text" id = "email" name= "email" placeholder = "E-Mail" onChange = {change} value={form.email}></input><nobr/>
                        <input type = "text" id = "unternehmen" name= "unternehmen" placeholder = "Unternehmen" onChange = {change} value={form.unternehmen}></input><br/>
                       
                        <textarea type = "text" id = "nachricht" name= "nachricht" placeholder = "Nachricht" onChange = {change} value={form.nachricht}></textarea><br/>
                        <label>
                        <input type = "checkbox" id = "datenschutzeinverständnis" name = "datenschutz" onChange = {()=>setForm({...form, datenschutz: !form.datenschutz})} value={form.datenschutz}></input>Ich habe die Datenschutzbestimmungen gelesen und akzeptiere sie.*
                        </label> <br/>
                        <p className = "msg" style={{color: `${msg==successMsg? "green":"red"}`}} >{msg}</p>
                        <input className ="button" type="submit" value = "absenden"></input>
                    </form>
                </div>

            </div>


    
</>
    )
}

export default HomeView